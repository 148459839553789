import {
  BetSlipPoints,
  stages,
  GameBetPoints,
  GameIdGameBetPointsMap,
  FinalStageType,
  TeamInFinalStagesPoints,
  finalStages,
  StageType,
} from './betSlip.util';

const calculateGameResult = (
  homeScore: number | undefined | null,
  awayScore: number | undefined | null,
): '1' | 'X' | '2' | null => {
  if (typeof homeScore !== 'number' || typeof awayScore !== 'number') {
    return null;
  }

  if (homeScore > awayScore) {
    return '1';
  }

  if (homeScore < awayScore) {
    return '2';
  }

  return 'X';
};

export const correctTeamInStagePointsMap: Record<FinalStageType | string, number> = {
  roundOfSixteen: 2,
  quaterFinal: 4,
  semiFinal: 6,
  final: 8,
  bronzeMedalGame: 8,
};

export const hasFinishedPreviousStage = (stageType: StageType, games: GraphQL.Game[]): boolean => {
  const prevStage = stageType ? stages[stages.indexOf(stageType) - 1] : '';

  return (
    !!prevStage &&
    games.filter((game) => game.stage?.type === prevStage).every(({ status }) => status === 'FINISHED')
  );
};

const hasFinishedGroupStage = (games: GraphQL.Game[]) =>
  games.filter((game) => game.stage?.type === 'group').every(({ status }) => status === 'FINISHED');

const calculateTeamsInStagePoints = (
  stageType: StageType,
  team: GraphQL.Team | undefined | null,
  games: GraphQL.Game[],
): number | undefined => {
  if (stageType === 'group' || !hasFinishedGroupStage(games) || !team || !stageType) {
    return;
  }

  const stageTeams = games
    .filter(({ stage }) => stage?.type === stageType)
    .flatMap(({ homeTeam, awayTeam }) => [homeTeam, awayTeam])
    .filter((team): team is GraphQL.Team => !!team);

  return stageTeams.some((stageTeam) => team.id === stageTeam.id)
    ? correctTeamInStagePointsMap[stageType]
    : 0;
};

const calulateGamePoints = (gameBet: GraphQL.GameBet, games: GraphQL.Game[]): GameBetPoints => {
  const game = games.find(({ id }) => id === gameBet.game?.id);

  const goalsAfterNormalTime = (game?.goals || []).filter(
    (goal) => (game?.stage?.type === 'group' || goal.minute || 0) <= 90,
  );
  const gameHomeScore =
    goalsAfterNormalTime?.filter(({ team }) => team?.id === game?.homeTeam?.id).length || 0;
  const gameAwayScore =
    goalsAfterNormalTime?.filter(({ team }) => team?.id === game?.awayTeam?.id).length || 0;

  const gameResult = calculateGameResult(gameHomeScore, gameAwayScore);
  const gameBetResult = calculateGameResult(gameBet.homeScore, gameBet.awayScore);

  const homeScorePoints = gameHomeScore === gameBet.homeScore ? 2 : 0;
  const awayScorePoints = gameAwayScore === gameBet.awayScore ? 2 : 0;
  const resultPoints = gameResult === gameBetResult ? 2 : 0;

  const homeTeamPoints = calculateTeamsInStagePoints(
    game?.stage?.type as StageType,
    gameBet?.homeTeam,
    games,
  );
  const awayTeamPoints = calculateTeamsInStagePoints(
    game?.stage?.type as StageType,
    gameBet?.awayTeam,
    games,
  );

  return {
    gameBet,
    homeScorePoints,
    awayScorePoints,
    correctScorePoints: 0, // PDF only
    correctMatchPoints: 0, // PDF only
    homeTeamPoints, // For visuals Only
    awayTeamPoints, // For visuals Only
    resultPoints,
    total: homeScorePoints + awayScorePoints + resultPoints,
  };
};

const calculateTopGoalScorerPoints = (
  players: GraphQL.Player[] | undefined,
  { topGoalScorer }: GraphQL.BetSlip,
) => {
  const topGoalScorersIds =
    players
      ?.map((player) => ({
        ...player,
        goals: (player.goals || []).filter(
          ({ type, minute }) => type !== 'ownGoal' && (typeof minute !== 'number' || minute <= 120),
        ),
      }))
      .reduce((acc: GraphQL.Player[], curr) => {
        const isEqual = acc.some(({ goals }) => (goals || []).length === curr.goals.length);
        if (isEqual) {
          return [...acc, curr];
        }

        const isBetter = acc.every(({ goals }) => (goals || []).length < curr.goals.length);
        if (isBetter) {
          return [curr];
        }
        return acc;
      }, [])
      .map(({ id }) => id) || [];

  console.log({ topGoalScorersIds });
  return topGoalScorer && topGoalScorersIds.includes(topGoalScorer.id) ? 20 : 0;
};

const calculateLastGamePoints = (
  game: GraphQL.Game | undefined,
  winningTeam: GraphQL.Team | undefined | null,
) => {
  const gameHomeScore = game?.goals?.filter(({ team }) => team?.id === game?.homeTeam?.id).length || 0;
  const gameAwayScore = game?.goals?.filter(({ team }) => team?.id === game?.awayTeam?.id).length || 0;

  if (gameHomeScore > gameAwayScore || game?.forceWinners === 'HOME') {
    return winningTeam?.id === game?.homeTeam?.id ? 20 : 0;
  }

  if (gameAwayScore > gameHomeScore || game?.forceWinners === 'AWAY') {
    return winningTeam?.id === game?.awayTeam?.id ? 20 : 0;
  }

  return 0;
};

const calculateTeamsInFinalStagePoints = (
  games: GraphQL.Game[],
  betSlip: GraphQL.BetSlip,
): TeamInFinalStagesPoints =>
  finalStages.reduce((acc: TeamInFinalStagesPoints, stageType) => {
    if (!hasFinishedGroupStage(games)) {
      return { ...acc, [`${stageType}TeamsPoints`]: undefined };
    }

    const teamsInStage = games
      .filter((game) => game.stage?.type === stageType)
      .flatMap(({ homeTeam, awayTeam }) => [homeTeam, awayTeam])
      .filter((team): team is GraphQL.Team => !!team);

    const stagePoints = correctTeamInStagePointsMap[stageType];
    const stageTeamPoints =
      betSlip.gameBets
        ?.filter(({ game }) => game?.stage?.type === stageType)
        ?.flatMap(({ homeTeam, awayTeam }) => [homeTeam, awayTeam])
        .filter((gameBetTeam) => teamsInStage.some((team) => team.id === gameBetTeam?.id))
        .map(() => stagePoints)
        .reduce((acc: number, curr) => acc + curr, 0) || 0;

    return { ...acc, [`${stageType}TeamsPoints`]: stageTeamPoints };
  }, {});

type CalculateExcelBetSlipPointsArgs = {
  includeLive: boolean;
  betSlip: GraphQL.BetSlip;
  games: GraphQL.Game[];
  players?: GraphQL.Player[];
};

export const calculateExcelBetSlipPoints = ({
  includeLive,
  betSlip,
  games,
  players,
}: CalculateExcelBetSlipPointsArgs): BetSlipPoints => {
  const { gameBets } = betSlip;
  const gameBetsPoints = (gameBets || []).reduce(
    (acc: GameIdGameBetPointsMap, gameBet) => ({
      ...acc,
      [gameBet?.game?.id || gameBet.id]: calulateGamePoints(gameBet, games),
    }),
    {},
  );

  const finalGame = games?.find(({ stage }) => stage?.type === 'final');
  const championPoints = calculateLastGamePoints(finalGame, betSlip.champions);

  const bronzeMedalGame = games?.find(({ stage }) => stage?.type === 'bronzeMedalGame');
  const bronzeMedalGameWinnersPoints = calculateLastGamePoints(bronzeMedalGame, betSlip.bronzeGameWinners);

  const topGoalScorerPoints = calculateTopGoalScorerPoints(players, betSlip);
  const totalGameBetPoints = Object.entries(gameBetsPoints).reduce((acc: number, [gameId, curr]) => {
    const game = games.find(({ id }) => id === gameId);
    return !game || game.status === 'UPCOMING' || (!includeLive && game.status === 'LIVE')
      ? acc
      : acc + curr.total;
  }, 0);

  const finalRoundsPoints = calculateTeamsInFinalStagePoints(games, betSlip);
  const countFinalRelatedPoints =
    finalGame?.status === 'FINISHED' || (includeLive && finalGame?.status === 'LIVE');

  console.log({
    countFinalRelatedPoints,
    championPoints,
    topGoalScorerPoints,
    betSlip: betSlip.user?.username,
  });

  return {
    gameBetsPoints,
    groupBetsPoints: {}, // PDF Only
    championPoints,
    bronzeMedalGameWinnersPoints,
    runnersUpPoints: 0, // PDF Only
    topGoalScorerPoints,
    ...finalRoundsPoints,
    total:
      totalGameBetPoints +
      Object.values(finalRoundsPoints).reduce((acc, curr) => acc + (curr || 0), 0) +
      (countFinalRelatedPoints ? championPoints : 0) +
      (countFinalRelatedPoints ? topGoalScorerPoints : 0),
  };
};
