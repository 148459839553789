import { calculateExcelBetSlipPoints } from './excelBetSlip.util';

export type GameBetPoints = {
  gameBet: GraphQL.GameBet;
  homeScorePoints: number; // Excell only
  awayScorePoints: number; // Excell only
  correctScorePoints: number; // PDF only
  resultPoints: number;
  homeTeamPoints: number | undefined;
  awayTeamPoints: number | undefined;
  correctMatchPoints: number;
  total: number;
};

export type GameIdGameBetPointsMap = {
  [key: string]: GameBetPoints;
};

export type GroupBetPoints = {
  group: GraphQL.Group | undefined;
  firstTeamPoints: number;
  secondTeamPoints: number;
  thirdTeamPoints: number;
  lastTeamPoints: number;
  total: number;
};

export type GroupIdGroupBetPointsMap = {
  [key: string]: GroupBetPoints;
};

export const stages = [
  'group',
  'roundOfSixteen',
  'quaterFinal',
  'semiFinal',
  'bronzeMedalGame',
  'final',
] as const;
export const finalStages = [stages[1], stages[2], stages[3], stages[4], stages[5]] as const;
export type StageType = typeof stages[number];
export type FinalStageType = typeof finalStages[number];
export type TeamInFinalStagesPoints = {
  [key in `${FinalStageType}TeamsPoints`]?: number;
};

export type BetSlipPoints = TeamInFinalStagesPoints & {
  gameBetsPoints: GameIdGameBetPointsMap;
  groupBetsPoints: GroupIdGroupBetPointsMap; // PDF only
  championPoints: number;
  bronzeMedalGameWinnersPoints: number;
  runnersUpPoints: number; // PDF only
  topGoalScorerPoints: number;
  total: number;
};

type CalculateBetSlipPointsArgs = {
  includeLive: boolean;
  betSlip: GraphQL.BetSlip;
  games: GraphQL.Game[];
  groups: Partial<GraphQL.Group>[];
  players: GraphQL.Player[];
};

export const calculateBetSlipPoints = ({
  includeLive,
  betSlip,
  games,
  players,
}: CalculateBetSlipPointsArgs): BetSlipPoints => {
  return calculateExcelBetSlipPoints({ includeLive, betSlip, games, players });
};
