import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthContextProvider } from '../context/AuthContext';
import { BetslipContextProvider } from '../context/BetslipContext';
import './../assets/scss/App.scss';

import { Routes } from './Routes';

const queryClient = new QueryClient();

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <BetslipContextProvider>
        <Routes />
      </BetslipContextProvider>
    </AuthContextProvider>
  </QueryClientProvider>
);
export default App;
