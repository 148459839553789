import React, { cloneElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Avatar, IconButton, Toolbar, Typography, useScrollTrigger } from '@material-ui/core';
import { NavDrawer } from './NavDrawer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../context/AuthContext';

type Props = {
  window?: () => Window;
  children: React.ReactElement;
};

const ElevationScroll = ({ children, window }: Props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  title: {},
});

const AppNameLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

export const AppNavbar = () => {
  const classes = useStyles();
  const { authenticatedUser } = useAuth();

  return (
    <div className={classes.root}>
      <ElevationScroll>
        <AppBar style={{ backgroundColor: 'rgb(138 21 56)' }}>
          <Toolbar>
            <NavDrawer />
            <div style={{ flex: '1', display: 'flex' }}>
              <AppNameLink to="/">
                <Typography variant="h6" className={classes.title}>
                  VM-Tipset 2022
                </Typography>
              </AppNameLink>
            </div>
            {authenticatedUser && (
              <IconButton
                size="medium"
                component={Link}
                to={`/user/${authenticatedUser.slug}`}
                title={authenticatedUser.username || 'User'}
              >
                <Avatar src={authenticatedUser.image?.url || ''}></Avatar>
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
};
