import {
  Card,
  CardActionArea,
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getGameBetScore, getTeamGameGoals, teamlogoFallback } from '../utils/game.util';
import { UserBetslipTable } from './UserBetslipTable';

const GamesContainer = styled.div`
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (min-width: 60em) {
    flex-direction: column-reverse;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    max-height: calc(100vh - 120px);
  }
`;

const GameContainer = styled.div`
  min-width: 70vw;
  scroll-snap-align: start;

  @media (min-width: 60em) {
    min-width: 100%;
  }
`;

const GameContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GameTime = styled.div<{ status: string }>`
  padding: 12px;
  width: 100px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) => {
    switch (status) {
      case 'LIVE':
        return '#5eb762';
      default:
        return '#ffffff';
    }
  }};
`;

const GameTeams = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GameBetContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TeamScore = styled.div<{ status: string }>`
  padding: 0 12px;
  width: 14px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ status }) => {
    switch (status) {
      case 'LIVE':
        return '#82c785';
      case 'FINISHED':
        return '#e1e1e1';
      default:
        return '#ffffff';
    }
  }};
`;

const TeamName = styled.span`
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TeamImg = styled.img`
  margin: 0 4px;
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

const GameTeam = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;

  img {
    margin: 0 8px;
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
`;

const findStartGameIndex = (games: GraphQL.Game[]): number => {
  const now = moment().format('YYYY-MM-DD');
  const allGameIds = games.map(({ id }) => id);
  const nextGames = getNextGames(0);

  if (nextGames.every(({ status }) => status !== 'UPCOMING')) {
    return allGameIds.indexOf(nextGames[nextGames.length - 1]?.id) || 0;
  }

  const startGame =
    nextGames?.find(({ status }) => {
      return status === 'LIVE' || status === 'UPCOMING';
    }) || nextGames[0];

  return allGameIds.indexOf(startGame.id) || 0;

  function getNextGames(daysChecked: number): GraphQL.Game[] {
    const dateToCheck = moment(now).add(daysChecked).format('YYYY-MM-DD');
    const dayGames = games.filter(({ startTime }) => startTime.includes(dateToCheck));
    if (dayGames.length) {
      return dayGames;
    }

    if (daysChecked === 10) {
      return games;
    }

    return getNextGames(daysChecked + 1);
  }
};

const getGameTime = ({ status, startTime, matchTime }: GraphQL.Game): string[] => {
  switch (status) {
    case 'LIVE':
      return ['Live', matchTime].filter((chunk): chunk is string => !!chunk);
    case 'FINISHED': {
      const today = moment().format('YYYY-MM-DD');
      const yesterday = moment(today).subtract(1, 'day').format('YYYY-MM-DD');

      if (startTime.includes(today)) {
        return ['Idag', 'Slut'];
      }

      if (startTime.includes(yesterday)) {
        return ['Igår', 'Slut'];
      }

      const date = moment(startTime).format('MM/DD');
      return [date, 'Slut'];
    }
    case 'UPCOMPING':
    default: {
      const today = moment().format('YYYY-MM-DD');
      const tomorrow = moment(today).add(1, 'day').format('YYYY-MM-DD');
      const startHours = moment(startTime).format('HH:mm');
      if (startTime.includes(today)) {
        return ['Idag', startHours];
      }

      if (startTime.includes(tomorrow)) {
        return ['Imorgon', startHours];
      }

      const weekDayAndDate = moment(startTime).format('ddd MM/DD');
      return [weekDayAndDate[0].toUpperCase() + weekDayAndDate.slice(1), startHours];
    }
  }
};

type GameCardProps = {
  game: GraphQL.Game;
  onClick?: (game: GraphQL.Game) => void;
};

const GameCard = ({ game, onClick }: GameCardProps) => {
  const gameTime = getGameTime(game);
  const status = game.status || '';

  return (
    <div>
      <Typography style={{ padding: '12px' }} variant="subtitle2">
        {game.stage?.type === 'group' ? game.group?.name : game.stage?.name}
      </Typography>
      <Card style={{ height: '100%' }} onClick={() => onClick?.(game)}>
        <CardActionArea disableRipple={!!onClick}>
          <GameContent>
            <GameTeams>
              <GameTeam>
                <TeamName>
                  <img
                    src={game.homeTeam?.image?.url || teamlogoFallback}
                    alt={game.homeTeam?.name + ' ikon'}
                  />
                  {game.homeTeam?.name}
                </TeamName>
                <TeamScore status={status}>
                  {status !== 'UPCOMING' && getTeamGameGoals(game.goals || [], game.homeTeam?.id || '')}
                </TeamScore>
              </GameTeam>
              <GameTeam>
                <TeamName>
                  <img
                    src={game.awayTeam?.image?.url || teamlogoFallback}
                    alt={game.awayTeam?.name + ' ikon'}
                  />
                  {game.awayTeam?.name}
                </TeamName>
                <TeamScore status={status}>
                  {status !== 'UPCOMING' && getTeamGameGoals(game.goals || [], game.awayTeam?.id || '')}
                </TeamScore>
              </GameTeam>
            </GameTeams>
            <GameTime status={status}>
              {gameTime.map((chunk) => (
                <div key={chunk}>{chunk}</div>
              ))}
            </GameTime>
          </GameContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

type Props = {
  games: GraphQL.Game[];
  betSlips: GraphQL.BetSlip[];
  groups: GraphQL.Group[] | undefined;
};

export const Games = ({ games, betSlips, groups }: Props) => {
  const startGameIndex = findStartGameIndex(games || []);
  const gameRefs = games.map(() => React.createRef<HTMLDivElement>());
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState<GraphQL.Game>();

  useEffect(() => {
    gameRefs[startGameIndex].current?.scrollIntoView();
  }, []);

  return (
    <>
      <Dialog
        open={modalOpen}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setModalOpen(false);
          setTimeout(() => {
            setSelectedGame(undefined);
          }, 200);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {selectedGame ? (
          <>
            <DialogTitle>
              <GameCard game={selectedGame} />
            </DialogTitle>
            <DialogContent dividers>
              <UserBetslipTable
                games={games}
                betSlips={betSlips}
                groups={groups}
                heading={() => (
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Spelare</TableCell>
                      <TableCell align="right">
                        <span>Bet</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                rowData={(betSlip) => {
                  const gameBet = betSlip.gameBets?.find((gameBet) => gameBet.game?.id === selectedGame.id);
                  return (
                    <TableCell align="right">
                      <GameBetContainer>
                        {selectedGame?.stage?.type === 'group' ? null : (
                          <TeamImg
                            src={gameBet?.homeTeam?.image?.url || ''}
                            alt={gameBet?.homeTeam?.name || ''}
                          />
                        )}
                        {getGameBetScore(selectedGame, betSlip)}
                        {selectedGame?.stage?.type === 'group' ? null : (
                          <TeamImg
                            src={gameBet?.awayTeam?.image?.url || ''}
                            alt={gameBet?.awayTeam?.name || ''}
                          />
                        )}
                      </GameBetContainer>
                    </TableCell>
                  );
                }}
              />
            </DialogContent>
          </>
        ) : null}
      </Dialog>
      <GamesContainer>
        {games?.map((game, i) => (
          <GameContainer key={game.id} ref={gameRefs[i]}>
            <GameCard
              game={game}
              onClick={() => {
                setModalOpen(true);
                setSelectedGame(game);
              }}
            />
          </GameContainer>
        ))}
      </GamesContainer>
    </>
  );
};
