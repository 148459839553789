import { API_HOST } from '../../../config';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query';

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(`${API_HOST}/api/graphql` as string, {
      method: 'POST',
      ...{ headers: { 'content-type': 'application/json' } },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
export const AuthUserPropsFragmentDoc = `
    fragment authUserProps on User {
  id
  username
  fullName
  firstName
  lastName
  slug
  email
  isAdmin
  image {
    id
    url
  }
  betSlip {
    id
  }
}
    `;
export const BetSlipPropsFragmentDoc = `
    fragment betSlipProps on BetSlip {
  user {
    id
    username
    slug
    image {
      id
      url
    }
  }
  id
  gameBets {
    game {
      id
      status
      stage {
        type
      }
    }
    homeTeam {
      id
      name
      shortName
      image {
        id
        url
      }
    }
    awayTeam {
      id
      name
      shortName
      image {
        id
        url
      }
    }
    homeScore
    awayScore
  }
  champions {
    id
    name
    image {
      url
    }
  }
  bronzeGameWinners {
    id
    name
    image {
      url
    }
  }
  topGoalScorer {
    id
  }
}
    `;
export const GamePropsFragmentDoc = `
    fragment gameProps on Game {
  id
  gameNumber
  startTime
  matchTime
  stage {
    name
    type
    mode
  }
  status
  stadium
  forceWinners
  awayTeam {
    id
    name
    shortName
    image {
      id
      url
    }
  }
  homeTeam {
    id
    name
    shortName
    image {
      id
      url
    }
  }
  goals {
    id
    type
    minute
    team {
      id
    }
  }
  group {
    id
    name
  }
}
    `;
export const GroupPropsFragmentDoc = `
    fragment groupProps on Group {
  id
  name
  games(orderBy: {gameNumber: asc}) {
    id
    gameNumber
    homeTeam {
      id
      name
      shortName
      image {
        id
        url
      }
    }
    awayTeam {
      id
      name
      shortName
      image {
        id
        url
      }
    }
    stage {
      name
      type
      mode
    }
    status
    stadium
    startTime
    goals {
      team {
        id
      }
    }
  }
  teams {
    id
    name
    image {
      id
      url
    }
  }
}
    `;
export const PlayerPropsFragmentDoc = `
    fragment playerProps on Player {
  id
  firstName
  lastName
  team {
    id
  }
  image {
    id
    url
  }
  goals {
    minute
    id
    type
  }
}
    `;
export const TeamsPropsFragmentDoc = `
    fragment teamsProps on Team {
  id
  name
  shortName
  players {
    id
  }
  image {
    id
    url
  }
}
    `;
export const UserBetSlipPropsFragmentDoc = `
    fragment userBetSlipProps on BetSlip {
  id
  gameBets {
    game {
      id
      stage {
        type
      }
      status
    }
    homeTeam {
      id
      name
      shortName
    }
    awayTeam {
      id
      name
      shortName
    }
    homeScore
    awayScore
  }
  topGoalScorer {
    id
    firstName
    lastName
    image {
      id
      url
    }
    goals {
      id
      type
      minute
    }
  }
  champions {
    id
    name
    image {
      url
    }
  }
  bronzeGameWinners {
    id
    name
    image {
      url
    }
  }
}
    `;
export const UserPropsFragmentDoc = `
    fragment userProps on User {
  id
  username
  fullName
  firstName
  lastName
  slug
  email
  isAdmin
  image {
    id
    url
  }
  betSlip {
    ...userBetSlipProps
  }
}
    ${UserBetSlipPropsFragmentDoc}`;
export const SignInDocument = `
    mutation signIn($username: String!, $password: String!) {
  authenticateUserWithPassword(username: $username, password: $password) {
    __typename
    ... on UserAuthenticationWithPasswordSuccess {
      sessionToken
      item {
        ...authUserProps
      }
    }
    ... on UserAuthenticationWithPasswordFailure {
      message
    }
  }
}
    ${AuthUserPropsFragmentDoc}`;
export const useSignInMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<GraphQL.SignInMutation, TError, GraphQL.SignInMutationVariables, TContext>,
) =>
  useMutation<GraphQL.SignInMutation, TError, GraphQL.SignInMutationVariables, TContext>(
    ['signIn'],
    (variables?: GraphQL.SignInMutationVariables) =>
      fetcher<GraphQL.SignInMutation, GraphQL.SignInMutationVariables>(SignInDocument, variables)(),
    options,
  );
useSignInMutation.fetcher = (variables: GraphQL.SignInMutationVariables) =>
  fetcher<GraphQL.SignInMutation, GraphQL.SignInMutationVariables>(SignInDocument, variables);
export const SignOutDocument = `
    mutation signOut {
  endSession
}
    `;
export const useSignOutMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<GraphQL.SignOutMutation, TError, GraphQL.SignOutMutationVariables, TContext>,
) =>
  useMutation<GraphQL.SignOutMutation, TError, GraphQL.SignOutMutationVariables, TContext>(
    ['signOut'],
    (variables?: GraphQL.SignOutMutationVariables) =>
      fetcher<GraphQL.SignOutMutation, GraphQL.SignOutMutationVariables>(SignOutDocument, variables)(),
    options,
  );
useSignOutMutation.fetcher = (variables?: GraphQL.SignOutMutationVariables) =>
  fetcher<GraphQL.SignOutMutation, GraphQL.SignOutMutationVariables>(SignOutDocument, variables);
export const AuthenticatedUserDocument = `
    query authenticatedUser {
  authenticatedItem {
    ... on User {
      ...authUserProps
    }
  }
}
    ${AuthUserPropsFragmentDoc}`;
export const useAuthenticatedUserQuery = <TData = GraphQL.AuthenticatedUserQuery, TError = unknown>(
  variables?: GraphQL.AuthenticatedUserQueryVariables,
  options?: UseQueryOptions<GraphQL.AuthenticatedUserQuery, TError, TData>,
) =>
  useQuery<GraphQL.AuthenticatedUserQuery, TError, TData>(
    variables === undefined ? ['authenticatedUser'] : ['authenticatedUser', variables],
    fetcher<GraphQL.AuthenticatedUserQuery, GraphQL.AuthenticatedUserQueryVariables>(
      AuthenticatedUserDocument,
      variables,
    ),
    options,
  );

useAuthenticatedUserQuery.getKey = (variables?: GraphQL.AuthenticatedUserQueryVariables) =>
  variables === undefined ? ['authenticatedUser'] : ['authenticatedUser', variables];
useAuthenticatedUserQuery.fetcher = (variables?: GraphQL.AuthenticatedUserQueryVariables) =>
  fetcher<GraphQL.AuthenticatedUserQuery, GraphQL.AuthenticatedUserQueryVariables>(
    AuthenticatedUserDocument,
    variables,
  );
export const GetBetSlipsDocument = `
    query getBetSlips {
  betSlips {
    ...betSlipProps
  }
}
    ${BetSlipPropsFragmentDoc}`;
export const useGetBetSlipsQuery = <TData = GraphQL.GetBetSlipsQuery, TError = unknown>(
  variables?: GraphQL.GetBetSlipsQueryVariables,
  options?: UseQueryOptions<GraphQL.GetBetSlipsQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetBetSlipsQuery, TError, TData>(
    variables === undefined ? ['getBetSlips'] : ['getBetSlips', variables],
    fetcher<GraphQL.GetBetSlipsQuery, GraphQL.GetBetSlipsQueryVariables>(GetBetSlipsDocument, variables),
    options,
  );

useGetBetSlipsQuery.getKey = (variables?: GraphQL.GetBetSlipsQueryVariables) =>
  variables === undefined ? ['getBetSlips'] : ['getBetSlips', variables];
useGetBetSlipsQuery.fetcher = (variables?: GraphQL.GetBetSlipsQueryVariables) =>
  fetcher<GraphQL.GetBetSlipsQuery, GraphQL.GetBetSlipsQueryVariables>(GetBetSlipsDocument, variables);
export const GetBetSlipDocument = `
    query getBetSlip($id: ID) {
  betSlip(where: {id: $id}) {
    ...betSlipProps
  }
}
    ${BetSlipPropsFragmentDoc}`;
export const useGetBetSlipQuery = <TData = GraphQL.GetBetSlipQuery, TError = unknown>(
  variables?: GraphQL.GetBetSlipQueryVariables,
  options?: UseQueryOptions<GraphQL.GetBetSlipQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetBetSlipQuery, TError, TData>(
    variables === undefined ? ['getBetSlip'] : ['getBetSlip', variables],
    fetcher<GraphQL.GetBetSlipQuery, GraphQL.GetBetSlipQueryVariables>(GetBetSlipDocument, variables),
    options,
  );

useGetBetSlipQuery.getKey = (variables?: GraphQL.GetBetSlipQueryVariables) =>
  variables === undefined ? ['getBetSlip'] : ['getBetSlip', variables];
useGetBetSlipQuery.fetcher = (variables?: GraphQL.GetBetSlipQueryVariables) =>
  fetcher<GraphQL.GetBetSlipQuery, GraphQL.GetBetSlipQueryVariables>(GetBetSlipDocument, variables);
export const GetGamesDocument = `
    query getGames {
  games(orderBy: {startTime: asc}) {
    ...gameProps
  }
}
    ${GamePropsFragmentDoc}`;
export const useGetGamesQuery = <TData = GraphQL.GetGamesQuery, TError = unknown>(
  variables?: GraphQL.GetGamesQueryVariables,
  options?: UseQueryOptions<GraphQL.GetGamesQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetGamesQuery, TError, TData>(
    variables === undefined ? ['getGames'] : ['getGames', variables],
    fetcher<GraphQL.GetGamesQuery, GraphQL.GetGamesQueryVariables>(GetGamesDocument, variables),
    options,
  );

useGetGamesQuery.getKey = (variables?: GraphQL.GetGamesQueryVariables) =>
  variables === undefined ? ['getGames'] : ['getGames', variables];
useGetGamesQuery.fetcher = (variables?: GraphQL.GetGamesQueryVariables) =>
  fetcher<GraphQL.GetGamesQuery, GraphQL.GetGamesQueryVariables>(GetGamesDocument, variables);
export const GetGroupsDocument = `
    query getGroups {
  groups(orderBy: {name: asc}) {
    ...groupProps
  }
}
    ${GroupPropsFragmentDoc}`;
export const useGetGroupsQuery = <TData = GraphQL.GetGroupsQuery, TError = unknown>(
  variables?: GraphQL.GetGroupsQueryVariables,
  options?: UseQueryOptions<GraphQL.GetGroupsQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetGroupsQuery, TError, TData>(
    variables === undefined ? ['getGroups'] : ['getGroups', variables],
    fetcher<GraphQL.GetGroupsQuery, GraphQL.GetGroupsQueryVariables>(GetGroupsDocument, variables),
    options,
  );

useGetGroupsQuery.getKey = (variables?: GraphQL.GetGroupsQueryVariables) =>
  variables === undefined ? ['getGroups'] : ['getGroups', variables];
useGetGroupsQuery.fetcher = (variables?: GraphQL.GetGroupsQueryVariables) =>
  fetcher<GraphQL.GetGroupsQuery, GraphQL.GetGroupsQueryVariables>(GetGroupsDocument, variables);
export const GetGroupDocument = `
    query getGroup($id: ID) {
  group(where: {id: $id}) {
    ...groupProps
  }
}
    ${GroupPropsFragmentDoc}`;
export const useGetGroupQuery = <TData = GraphQL.GetGroupQuery, TError = unknown>(
  variables?: GraphQL.GetGroupQueryVariables,
  options?: UseQueryOptions<GraphQL.GetGroupQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetGroupQuery, TError, TData>(
    variables === undefined ? ['getGroup'] : ['getGroup', variables],
    fetcher<GraphQL.GetGroupQuery, GraphQL.GetGroupQueryVariables>(GetGroupDocument, variables),
    options,
  );

useGetGroupQuery.getKey = (variables?: GraphQL.GetGroupQueryVariables) =>
  variables === undefined ? ['getGroup'] : ['getGroup', variables];
useGetGroupQuery.fetcher = (variables?: GraphQL.GetGroupQueryVariables) =>
  fetcher<GraphQL.GetGroupQuery, GraphQL.GetGroupQueryVariables>(GetGroupDocument, variables);
export const GetPlayersDocument = `
    query getPlayers {
  players {
    ...playerProps
  }
}
    ${PlayerPropsFragmentDoc}`;
export const useGetPlayersQuery = <TData = GraphQL.GetPlayersQuery, TError = unknown>(
  variables?: GraphQL.GetPlayersQueryVariables,
  options?: UseQueryOptions<GraphQL.GetPlayersQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetPlayersQuery, TError, TData>(
    variables === undefined ? ['getPlayers'] : ['getPlayers', variables],
    fetcher<GraphQL.GetPlayersQuery, GraphQL.GetPlayersQueryVariables>(GetPlayersDocument, variables),
    options,
  );

useGetPlayersQuery.getKey = (variables?: GraphQL.GetPlayersQueryVariables) =>
  variables === undefined ? ['getPlayers'] : ['getPlayers', variables];
useGetPlayersQuery.fetcher = (variables?: GraphQL.GetPlayersQueryVariables) =>
  fetcher<GraphQL.GetPlayersQuery, GraphQL.GetPlayersQueryVariables>(GetPlayersDocument, variables);
export const GetPlayerDocument = `
    query getPlayer($id: ID) {
  player(where: {id: $id}) {
    ...playerProps
  }
}
    ${PlayerPropsFragmentDoc}`;
export const useGetPlayerQuery = <TData = GraphQL.GetPlayerQuery, TError = unknown>(
  variables?: GraphQL.GetPlayerQueryVariables,
  options?: UseQueryOptions<GraphQL.GetPlayerQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetPlayerQuery, TError, TData>(
    variables === undefined ? ['getPlayer'] : ['getPlayer', variables],
    fetcher<GraphQL.GetPlayerQuery, GraphQL.GetPlayerQueryVariables>(GetPlayerDocument, variables),
    options,
  );

useGetPlayerQuery.getKey = (variables?: GraphQL.GetPlayerQueryVariables) =>
  variables === undefined ? ['getPlayer'] : ['getPlayer', variables];
useGetPlayerQuery.fetcher = (variables?: GraphQL.GetPlayerQueryVariables) =>
  fetcher<GraphQL.GetPlayerQuery, GraphQL.GetPlayerQueryVariables>(GetPlayerDocument, variables);
export const GetTeamsDocument = `
    query getTeams {
  teams {
    ...teamsProps
  }
}
    ${TeamsPropsFragmentDoc}`;
export const useGetTeamsQuery = <TData = GraphQL.GetTeamsQuery, TError = unknown>(
  variables?: GraphQL.GetTeamsQueryVariables,
  options?: UseQueryOptions<GraphQL.GetTeamsQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetTeamsQuery, TError, TData>(
    variables === undefined ? ['getTeams'] : ['getTeams', variables],
    fetcher<GraphQL.GetTeamsQuery, GraphQL.GetTeamsQueryVariables>(GetTeamsDocument, variables),
    options,
  );

useGetTeamsQuery.getKey = (variables?: GraphQL.GetTeamsQueryVariables) =>
  variables === undefined ? ['getTeams'] : ['getTeams', variables];
useGetTeamsQuery.fetcher = (variables?: GraphQL.GetTeamsQueryVariables) =>
  fetcher<GraphQL.GetTeamsQuery, GraphQL.GetTeamsQueryVariables>(GetTeamsDocument, variables);
export const GetTeamDocument = `
    query getTeam($id: ID) {
  team(where: {id: $id}) {
    ...teamsProps
  }
}
    ${TeamsPropsFragmentDoc}`;
export const useGetTeamQuery = <TData = GraphQL.GetTeamQuery, TError = unknown>(
  variables?: GraphQL.GetTeamQueryVariables,
  options?: UseQueryOptions<GraphQL.GetTeamQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetTeamQuery, TError, TData>(
    variables === undefined ? ['getTeam'] : ['getTeam', variables],
    fetcher<GraphQL.GetTeamQuery, GraphQL.GetTeamQueryVariables>(GetTeamDocument, variables),
    options,
  );

useGetTeamQuery.getKey = (variables?: GraphQL.GetTeamQueryVariables) =>
  variables === undefined ? ['getTeam'] : ['getTeam', variables];
useGetTeamQuery.fetcher = (variables?: GraphQL.GetTeamQueryVariables) =>
  fetcher<GraphQL.GetTeamQuery, GraphQL.GetTeamQueryVariables>(GetTeamDocument, variables);
export const GetUsersDocument = `
    query getUsers {
  users {
    ...userProps
  }
}
    ${UserPropsFragmentDoc}`;
export const useGetUsersQuery = <TData = GraphQL.GetUsersQuery, TError = unknown>(
  variables?: GraphQL.GetUsersQueryVariables,
  options?: UseQueryOptions<GraphQL.GetUsersQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetUsersQuery, TError, TData>(
    variables === undefined ? ['getUsers'] : ['getUsers', variables],
    fetcher<GraphQL.GetUsersQuery, GraphQL.GetUsersQueryVariables>(GetUsersDocument, variables),
    options,
  );

useGetUsersQuery.getKey = (variables?: GraphQL.GetUsersQueryVariables) =>
  variables === undefined ? ['getUsers'] : ['getUsers', variables];
useGetUsersQuery.fetcher = (variables?: GraphQL.GetUsersQueryVariables) =>
  fetcher<GraphQL.GetUsersQuery, GraphQL.GetUsersQueryVariables>(GetUsersDocument, variables);
export const GetUserByIdDocument = `
    query getUserById($id: ID) {
  user(where: {id: $id}) {
    ...userProps
  }
}
    ${UserPropsFragmentDoc}`;
export const useGetUserByIdQuery = <TData = GraphQL.GetUserByIdQuery, TError = unknown>(
  variables?: GraphQL.GetUserByIdQueryVariables,
  options?: UseQueryOptions<GraphQL.GetUserByIdQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetUserByIdQuery, TError, TData>(
    variables === undefined ? ['getUserById'] : ['getUserById', variables],
    fetcher<GraphQL.GetUserByIdQuery, GraphQL.GetUserByIdQueryVariables>(GetUserByIdDocument, variables),
    options,
  );

useGetUserByIdQuery.getKey = (variables?: GraphQL.GetUserByIdQueryVariables) =>
  variables === undefined ? ['getUserById'] : ['getUserById', variables];
useGetUserByIdQuery.fetcher = (variables?: GraphQL.GetUserByIdQueryVariables) =>
  fetcher<GraphQL.GetUserByIdQuery, GraphQL.GetUserByIdQueryVariables>(GetUserByIdDocument, variables);
export const GetUserBySlugDocument = `
    query getUserBySlug($slug: String) {
  user(where: {slug: $slug}) {
    ...userProps
  }
}
    ${UserPropsFragmentDoc}`;
export const useGetUserBySlugQuery = <TData = GraphQL.GetUserBySlugQuery, TError = unknown>(
  variables?: GraphQL.GetUserBySlugQueryVariables,
  options?: UseQueryOptions<GraphQL.GetUserBySlugQuery, TError, TData>,
) =>
  useQuery<GraphQL.GetUserBySlugQuery, TError, TData>(
    variables === undefined ? ['getUserBySlug'] : ['getUserBySlug', variables],
    fetcher<GraphQL.GetUserBySlugQuery, GraphQL.GetUserBySlugQueryVariables>(
      GetUserBySlugDocument,
      variables,
    ),
    options,
  );

useGetUserBySlugQuery.getKey = (variables?: GraphQL.GetUserBySlugQueryVariables) =>
  variables === undefined ? ['getUserBySlug'] : ['getUserBySlug', variables];
useGetUserBySlugQuery.fetcher = (variables?: GraphQL.GetUserBySlugQueryVariables) =>
  fetcher<GraphQL.GetUserBySlugQuery, GraphQL.GetUserBySlugQueryVariables>(GetUserBySlugDocument, variables);
