import React, { useContext, createContext, useState, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  useAuthenticatedUserQuery,
  useSignInMutation,
  useSignOutMutation,
} from '../resources/generated/tipsstugan-cms';

type AuthContextState = {
  authenticatedUser: GraphQL.User | null | undefined;
  isLoading: boolean;
  authIsFetched: boolean;
  signIn: (username: string, password: string) => Promise<GraphQL.SignInMutation>;
  signOut: () => Promise<void>;
};

const AuthContext = createContext<Partial<AuthContextState>>({});

export const useAuth = () => {
  return useContext(AuthContext);
};

type ProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const AuthContextProvider = ({ children }: ProviderProps) => {
  const [storedAuthenticatedUser, setStoredAuthenticatedUser] = useLocalStorage<GraphQL.User | null>(
    'user',
    null,
  );

  const [isSignedOut, setISSignedOut] = useState(false);
  const {
    isLoading: isLoadingSignIn,
    mutateAsync: signIn,
    data: { authenticateUserWithPassword } = {},
  } = useSignInMutation();
  const {
    data: { authenticatedItem } = {},
    isLoading: isLoadingAuth,
    isFetched: authIsFetched,
  } = useAuthenticatedUserQuery({}, { enabled: !storedAuthenticatedUser });
  const { mutateAsync: signOut } = useSignOutMutation();

  useEffect(() => {
    if (authenticateUserWithPassword?.__typename === 'UserAuthenticationWithPasswordSuccess') {
      authenticateUserWithPassword.item &&
        JSON.stringify(authenticateUserWithPassword.item) !== JSON.stringify(storedAuthenticatedUser) &&
        setStoredAuthenticatedUser(authenticateUserWithPassword.item as GraphQL.User);
    } else {
      authenticatedItem &&
        JSON.stringify(authenticatedItem) !== JSON.stringify(storedAuthenticatedUser) &&
        setStoredAuthenticatedUser(authenticatedItem as GraphQL.User);
    }
  }, [authenticateUserWithPassword, storedAuthenticatedUser, authenticatedItem]);

  const value: AuthContextState = {
    authenticatedUser: isSignedOut ? undefined : storedAuthenticatedUser,
    isLoading: isLoadingSignIn || isLoadingAuth,
    authIsFetched,
    signIn: (username: string, password: string) =>
      signIn({ username, password }).then((data) => {
        setISSignedOut(false);
        return data;
      }),
    signOut: () =>
      signOut({}).then(() => {
        setISSignedOut(true);
        setStoredAuthenticatedUser(null);
        window.localStorage.clear();
      }),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
