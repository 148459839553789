import {
  TableCell,
  Container,
  CircularProgress,
  Switch,
  FormControlLabel,
  Typography,
  IconButton,
  Drawer,
  Grid,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from '../hooks/useWindowSize';
import { useBetslipContext } from '../context/BetslipContext';
import { Games } from './Games';
import { UserBetslipTable } from './UserBetslipTable';
import { LocalStorage } from 'ttl-localstorage';
LocalStorage.put('test', 'test');

const TableCellSmall = styled(TableCell)`
  width: 10%;
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 48px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SettingsButton = styled(IconButton)`
  margin-right: 8px;
`;

const SettingsDrawerContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BetSlipTable = () => {
  const { width, height } = useWindowSize();

  const { betSlips, games, groups, players, isFetchingBetslips, isFetchingGames } = useBetslipContext();
  const hasLiveGame = games?.some(({ status }) => status === 'LIVE') || false;
  const finalGame = games?.find(({ stage }) => stage?.type === 'final');
  const [showLiveTable, setShowLiveTable] = useState(hasLiveGame);
  const [settingsDrawerOpen, setSettingsDrawerOpen] = useState(false);

  useEffect(() => {
    setShowLiveTable(hasLiveGame);
  }, [hasLiveGame]);

  const isLoading = !betSlips || !games;

  return (
    <Container style={{ height: '100%' }}>
      {isLoading ? (
        <LoadingSpinnerWrapper>
          <CircularProgress size={60} />
        </LoadingSpinnerWrapper>
      ) : (
        <>
          {finalGame?.status === 'FINISHED' && (
            <Confetti
              width={width}
              height={height}
              recycle={false}
              tweenDuration={15000}
              initialVelocityY={{ min: 5, max: 5 }}
              numberOfPieces={300}
            />
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <HeaderContainer>
                <TitleContainer>
                  <Typography variant={'h6'} component={'h6'} style={{ marginRight: '8px' }}>
                    Matcher
                  </Typography>
                  {isFetchingGames && (
                    <LoadingSpinnerWrapper>
                      <CircularProgress size={20} />
                    </LoadingSpinnerWrapper>
                  )}
                </TitleContainer>
              </HeaderContainer>
              {games.length ? (
                <Games groups={groups} games={games} betSlips={betSlips || []} />
              ) : (
                <CircularProgress size={20} />
              )}
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <HeaderContainer>
                <TitleContainer>
                  <Typography variant={'h6'} component={'h6'} style={{ marginRight: '8px' }}>
                    Tabell
                  </Typography>
                  {(isFetchingBetslips || isFetchingGames) && (
                    <LoadingSpinnerWrapper>
                      <CircularProgress size={20} />
                    </LoadingSpinnerWrapper>
                  )}
                </TitleContainer>
                <div>
                  <>
                    <SettingsButton onClick={() => setSettingsDrawerOpen(!settingsDrawerOpen)}>
                      <SettingsIcon />
                    </SettingsButton>
                    <Drawer
                      anchor="bottom"
                      open={settingsDrawerOpen}
                      onClose={() => setSettingsDrawerOpen(false)}
                    >
                      <Container style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                        <Typography variant={'h6'} component={'h6'} style={{ marginRight: '8px' }}>
                          Tabellinställingar
                        </Typography>
                        <SettingsDrawerContent>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showLiveTable}
                                onChange={() => setShowLiveTable(!showLiveTable)}
                                disabled={!hasLiveGame}
                                color="secondary"
                              />
                            }
                            label="Live"
                          />
                        </SettingsDrawerContent>
                      </Container>
                    </Drawer>
                  </>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showLiveTable}
                        onChange={() => setShowLiveTable(!showLiveTable)}
                        disabled={!hasLiveGame}
                        color="secondary"
                      />
                    }
                    label="Live"
                  />
                </div>
              </HeaderContainer>
              <UserBetslipTable
                betSlips={betSlips}
                games={games}
                groups={groups || []}
                players={players || []}
                showLiveTable={showLiveTable}
                renderPosition
                heading={() => (
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Spelare</TableCell>
                      <TableCell align="right">
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <span>Poäng</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                rowData={(betslip) => (
                  <TableCellSmall align="right">
                    {!betslip.points ? <CircularProgress size={20} /> : betslip.points.total}p
                  </TableCellSmall>
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};
