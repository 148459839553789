import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Avatar } from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { BetSlipPoints, calculateBetSlipPoints } from '../utils/betSlip.util';
import { useHistory } from 'react-router-dom';
('../context/AuthContext');

const TableRowStyled = styled(TableRow)`
  cursor: pointer;
`;

const TableCellTeamName = styled(TableCell)`
  display: flex;
  align-items: center;
`;

const medals = ['🥇', '🥈', '🥉'];

type BetSlipsWithPoints = GraphQL.BetSlip & { points?: BetSlipPoints };

const Position = ({ index, betSlips }: { index: number; betSlips: BetSlipsWithPoints[] }) => {
  const samePointsIndexes = betSlips.reduce(
    (acc: number[], { points }, i) => (points?.total === betSlips[index].points?.total ? [...acc, i] : acc),
    [],
  );

  const calcedIndex =
    !betSlips[index].points?.total && betSlips.length === samePointsIndexes.length
      ? index
      : Math.min(...samePointsIndexes) ?? index;

  const medal =
    medals[!betSlips[index].points?.total && betSlips.length === samePointsIndexes.length ? -1 : calcedIndex];

  return medal ? <span style={{ fontSize: '32px' }}>{medal}</span> : <>{calcedIndex + 1}.</>;
};

type Props = {
  betSlips: GraphQL.BetSlip[];
  games: GraphQL.Game[];
  groups: GraphQL.Group[];
  players: GraphQL.Player[];
  showLiveTable?: boolean;
  renderPosition?: boolean;
  heading: () => JSX.Element;
  rowData: (betSlip: BetSlipsWithPoints) => JSX.Element;
};

export const UserBetslipTable = ({
  betSlips,
  games,
  groups,
  players,
  showLiveTable = false,
  renderPosition = false,
  rowData,
  heading,
}: Props) => {
  const history = useHistory();

  const betSlipsWithPoints = betSlips
    ?.map((betSlip) => ({
      ...betSlip,
      points: !games
        ? undefined
        : calculateBetSlipPoints({
            includeLive: showLiveTable,
            betSlip,
            games,
            groups,
            players,
          }),
    }))
    .sort((a, b) => {
      const aTotal = a.points?.total || 0;
      const bTotal = b.points?.total || 0;

      if (bTotal > aTotal) {
        return 1;
      }

      if (bTotal < aTotal) {
        return -1;
      }
      return 0;
    });

  const goToUser = (slug: string) => {
    history.push(`/user/${slug}`);
  };

  return (
    <TableContainer component={Paper}>
      <Table id="test" size={(betSlips?.length || 0) > 12 ? 'small' : 'medium'}>
        {heading()}
        <TableBody>
          {betSlipsWithPoints?.map((betSlip, i) => (
            <TableRowStyled
              key={betSlip.id}
              hover
              onClick={() => betSlip.user?.slug && goToUser(betSlip.user?.slug)}
            >
              <TableCellTeamName>
                {renderPosition && (
                  <div
                    style={{
                      textAlign: 'center',
                      marginRight: '8px',
                      minWidth: '32px',
                      minHeight: '46px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Position index={i} betSlips={betSlipsWithPoints} />
                  </div>
                )}
                <Avatar
                  sizes={(betSlips?.length || 0) > 12 ? '32px' : '40px'}
                  aria-label="recipe"
                  src={betSlip.user?.image?.url || ''}
                />
                <span style={{ marginLeft: '8px' }}>{betSlip.user?.username}</span>
              </TableCellTeamName>
              {rowData(betSlip)}
            </TableRowStyled>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
