import React, { useContext, createContext, useEffect } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { defaultOptions } from '../resources';
import {
  useGetBetSlipsQuery,
  useGetGamesQuery,
  useGetGroupsQuery,
  useGetPlayersQuery,
} from '../resources/generated/tipsstugan-cms';

type BetslipContextState = {
  betSlips: GraphQL.BetSlip[];
  games: GraphQL.Game[];
  groups: GraphQL.Group[];
  players: GraphQL.Player[];
  isFetchingBetslips: boolean;
  isFetchingGames: boolean;
  isFetchingGroups: boolean;
  isFetchingPlayers: boolean;
};

const BetslipContext = createContext<Partial<BetslipContextState>>({});

export const useBetslipContext = () => useContext(BetslipContext);

type ProviderProps = {
  children: JSX.Element | JSX.Element[];
};

const hourInSec = 60 * 60;
const dayInSec = hourInSec * 24;

export const BetslipContextProvider = ({ children }: ProviderProps) => {
  const [storedBetSlips, setStoredBetSlips] = useLocalStorage<GraphQL.BetSlip[] | null>(
    'betSlips',
    null,
    dayInSec,
  );
  const [storedGroups, setStoredGroups] = useLocalStorage<GraphQL.Group[] | null>(
    'groups',
    null,
    dayInSec * 2,
  );
  const [storedGames, setStoredGames] = useLocalStorage<GraphQL.Game[] | null>('games', null, 180);

  const [storedPlayers, setStoredPlayers] = useLocalStorage<GraphQL.Player[] | null>(
    'players',
    null,
    hourInSec / 2,
  );

  const { data: betSlipsResponse, isFetching: isFetchingBetslips } = useGetBetSlipsQuery(
    {},
    { ...defaultOptions, enabled: !storedBetSlips },
  );

  const { data: gamesResponse, isFetching: isFetchingGames } = useGetGamesQuery(
    {},
    { ...defaultOptions, enabled: !storedGames },
  );

  const { data: groupsResponse, isFetching: isFetchingGroups } = useGetGroupsQuery(
    {},
    { ...defaultOptions, enabled: !storedGroups },
  );

  const { data: playersResponse, isFetching: isFetchingPlayers } = useGetPlayersQuery(
    {},
    { ...defaultOptions, enabled: !storedPlayers },
  );

  useEffect(() => {
    betSlipsResponse?.betSlips &&
      JSON.stringify(betSlipsResponse.betSlips) !== JSON.stringify(storedBetSlips) &&
      setStoredBetSlips(betSlipsResponse.betSlips as GraphQL.BetSlip[]);
  }, [betSlipsResponse?.betSlips, betSlipsResponse, storedBetSlips, setStoredBetSlips]);

  useEffect(() => {
    groupsResponse?.groups &&
      JSON.stringify(groupsResponse.groups) !== JSON.stringify(storedGroups) &&
      setStoredGroups(groupsResponse.groups);
  }, [groupsResponse?.groups, groupsResponse, storedGroups, setStoredGroups]);

  useEffect(() => {
    gamesResponse?.games &&
      JSON.stringify(gamesResponse.games) !== JSON.stringify(storedGames) &&
      setStoredGames(gamesResponse.games as GraphQL.Game[]);
  }, [gamesResponse?.games, gamesResponse, storedGames, setStoredGames]);

  useEffect(() => {
    playersResponse?.players &&
      JSON.stringify(playersResponse?.players) !== JSON.stringify(storedPlayers) &&
      setStoredPlayers(playersResponse?.players as GraphQL.Player[]);
  }, [playersResponse?.players, playersResponse, storedPlayers, setStoredPlayers]);

  const value: BetslipContextState = {
    betSlips: (storedBetSlips as GraphQL.BetSlip[]) || [],
    games: (storedGames as GraphQL.Game[]) || [],
    groups: (storedGroups as GraphQL.Group[]) || [],
    players: (storedPlayers as GraphQL.Player[]) || [],
    isFetchingBetslips,
    isFetchingGames,
    isFetchingGroups,
    isFetchingPlayers,
  };

  return <BetslipContext.Provider value={value}>{children}</BetslipContext.Provider>;
};
