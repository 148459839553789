const countPlayedGames = (team: GraphQL.Team, games: GraphQL.Game[]) => {
  const drawedGames = games.filter((game) => {
    if (team.id === game.homeTeam?.id || team.id === game.awayTeam?.id) {
      return game.status === 'FINISHED';
    }
    return false;
  });

  return drawedGames.length;
};

const getWonGamesOpponentIds = (team: GraphQL.Team, games: GraphQL.Game[]) => {
  const wonGames = games.filter((game) => {
    const gameHomeScore = game?.goals?.filter(({ team }) => team?.id === game?.homeTeam?.id).length || 0;
    const gameAwayScore = game?.goals?.filter(({ team }) => team?.id === game?.awayTeam?.id).length || 0;

    if (team.id === game.homeTeam?.id) {
      return gameHomeScore > gameAwayScore;
    }

    if (team.id === game.awayTeam?.id) {
      return gameHomeScore < gameAwayScore;
    }
    return false;
  });

  return wonGames.map(({ homeTeam, awayTeam }) => {
    if (homeTeam?.id === team.id) {
      return awayTeam?.id || '';
    }
    return homeTeam?.id || '';
  });
};

const getLostGamesOpponentIds = (team: GraphQL.Team, games: GraphQL.Game[]) => {
  const lostGames = games.filter((game) => {
    const gameHomeScore = game?.goals?.filter(({ team }) => team?.id === game?.homeTeam?.id).length || 0;
    const gameAwayScore = game?.goals?.filter(({ team }) => team?.id === game?.awayTeam?.id).length || 0;

    if (team.id === game.homeTeam?.id) {
      return gameHomeScore < gameAwayScore;
    }

    if (team.id === game.awayTeam?.id) {
      return gameHomeScore > gameAwayScore;
    }
    return false;
  });

  return lostGames.map(({ homeTeam, awayTeam }) => {
    if (homeTeam?.id === team.id) {
      return awayTeam?.id || '';
    }
    return homeTeam?.id || '';
  });
};

const countDrawedGames = (team: GraphQL.Team, games: GraphQL.Game[]) => {
  const drawedGames = games.filter((game) => {
    const gameHomeScore = game?.goals?.filter(({ team }) => team?.id === game?.homeTeam?.id).length || 0;
    const gameAwayScore = game?.goals?.filter(({ team }) => team?.id === game?.awayTeam?.id).length || 0;
    if (team.id === game.homeTeam?.id || team.id === game.awayTeam?.id) {
      return gameHomeScore === gameAwayScore;
    }
    return false;
  });

  return drawedGames.length;
};

const countGoals = (team: GraphQL.Team, games: GraphQL.Game[]) =>
  games.reduce(
    (acc, game) => {
      const gameHomeScore = game?.goals?.filter(({ team }) => team?.id === game?.homeTeam?.id).length || 0;
      const gameAwayScore = game?.goals?.filter(({ team }) => team?.id === game?.awayTeam?.id).length || 0;

      if (team.id === game.homeTeam?.id) {
        return {
          scoredGoalsCount: acc.scoredGoalsCount + gameHomeScore,
          concidedGoalsCount: acc.concidedGoalsCount - gameAwayScore,
        };
      }

      if (team.id === game.awayTeam?.id) {
        return {
          scoredGoalsCount: acc.scoredGoalsCount - gameHomeScore,
          concidedGoalsCount: acc.concidedGoalsCount + gameAwayScore,
        };
      }

      return acc;
    },
    { scoredGoalsCount: 0, concidedGoalsCount: 0 },
  );

type TeamTableData = {
  team: GraphQL.Team;
  wonGamesOpponentIds: string[];
  lostGamesOpponentIds: string[];
  playedGamesCount: number;
  wonGamesCount: number;
  drawedGamesCount: number;
  lostGamesCount: number;
  scoredGoalsCount: number;
  concidedGoalsCount: number;
  goalDifferance: number;
  points: number;
  place: number;
};

export const createTeamTableData = (team: GraphQL.Team, games?: GraphQL.Game[] | null): TeamTableData => {
  const finishedGames = games?.filter(({ status }) => status === 'FINISHED') || [];
  const wonGamesOpponentIds = getWonGamesOpponentIds(team, finishedGames);
  const wonGamesCount = wonGamesOpponentIds.length;
  const lostGamesOpponentIds = getLostGamesOpponentIds(team, finishedGames);
  const lostGamesCount = lostGamesOpponentIds.length;
  const drawedGamesCount = countDrawedGames(team, finishedGames);
  const { scoredGoalsCount, concidedGoalsCount } = countGoals(team, finishedGames);

  return {
    team,
    wonGamesOpponentIds,
    lostGamesOpponentIds,
    playedGamesCount: countPlayedGames(team, finishedGames),
    wonGamesCount,
    drawedGamesCount,
    lostGamesCount,
    scoredGoalsCount,
    concidedGoalsCount,
    goalDifferance: scoredGoalsCount + concidedGoalsCount,
    points: wonGamesCount * 3 + drawedGamesCount,
    place: 0,
  };
};

const isCircular = (teamsTableData: TeamTableData[]): boolean => {
  const lostCircular = teamsTableData.filter(({ lostGamesCount }) => lostGamesCount === 2).length === 3;
  const winCircular = teamsTableData.filter(({ wonGamesCount }) => wonGamesCount === 2).length === 3;

  return lostCircular || winCircular;
};

export const sortTeamData =
  (teamsTableData: TeamTableData[]) =>
  (a: TeamTableData, b: TeamTableData): number => {
    return sortByPoints();

    function sortByPoints() {
      if (a.points < b.points) {
        return 1;
      }

      if (a.points > b.points) {
        return -1;
      }
      return sortByGameResult();
    }

    function sortByGameResult() {
      if (isCircular(teamsTableData)) {
        return sortByGoalDifferance();
      }
      if (a.wonGamesOpponentIds.includes(b.team.id)) {
        return -1;
      }

      if (b.wonGamesOpponentIds.includes(a.team.id)) {
        return 1;
      }

      return sortByGoalDifferance();
    }

    function sortByGoalDifferance() {
      if (a.goalDifferance < b.goalDifferance) {
        return 1;
      }

      if (a.goalDifferance > b.goalDifferance) {
        return -1;
      }

      return sortByScoredGoal();
    }

    function sortByScoredGoal() {
      if (a.scoredGoalsCount < b.scoredGoalsCount) {
        return 1;
      }

      if (a.scoredGoalsCount > b.scoredGoalsCount) {
        return -1;
      }

      return 0;
    }
  };

type TableData = {
  place: number;
  team: GraphQL.Team;
  wonGamesOpponentIds: string[];
  lostGamesOpponentIds: string[];
  playedGamesCount: number;
  wonGamesCount: number;
  drawedGamesCount: number;
  lostGamesCount: number;
  scoredGoalsCount: number;
  concidedGoalsCount: number;
  goalDifferance: number;
  points: number;
};

export const createTeamsTableData = (
  teams?: GraphQL.Team[] | null,
  games?: GraphQL.Game[] | null,
): TableData[] => {
  const teamsTableData = teams?.map((team) => createTeamTableData(team, games)) || [];
  return teamsTableData
    .sort(sortTeamData(teamsTableData))
    .map((teamsTableData, i) => ({ ...teamsTableData, place: i + 1 }));
};
