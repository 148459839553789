import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TocIcon from '@material-ui/icons/Toc';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import { useAuth } from '../context/AuthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    fullList: {
      width: 'auto',
    },
  }),
);

export const NavDrawer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { authenticatedUser, signOut } = useAuth();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const protectedLinks = () => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Hem - Tabell" />
        </ListItem>
        <ListItem button component={Link} to="/grupper">
          <ListItemIcon>
            <TocIcon />
          </ListItemIcon>
          <ListItemText primary="Grupper" />
        </ListItem>
        <ListItem button onClick={() => signOut?.().then(() => history.push('/'))}>
          <ListItemIcon>
            <LockOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Logga ut" />
        </ListItem>
      </List>
    </div>
  );

  const publicLinks = () => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        <ListItem button component={Link} to="/logga-in">
          <ListItemIcon>
            <LockOpenOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Logga in" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        size="medium"
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        {authenticatedUser ? protectedLinks() : publicLinks()}
      </Drawer>
    </>
  );
};
