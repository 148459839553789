import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Groups } from './Groups';
import { AppNavbar } from './AppNavbar';
import { SignIn } from './Singin';
import { BetSlipTable } from './BetSlipTable';
import { UserDashboard } from './UserDashboard';

import { useAuth } from '../context/AuthContext';
import styled from 'styled-components';

const ChildrenContainer = styled.div`
  height: calc(100% - 76px);
  padding-top: 76px;
`;

export const Routes = () => {
  return (
    <Router>
      <AppNavbar />
      <Switch>
        <Route path="/logga-in">
          <SignIn />
        </Route>
        <PrivateRoute path="/grupper">
          <Groups />
        </PrivateRoute>
        <PrivateRoute path="/user/:slug">
          <UserDashboard />
        </PrivateRoute>
        <PrivateRoute path="">
          <BetSlipTable />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
type Props = {
  children?: JSX.Element | JSX.Element[];
  path?: string;
};

function PrivateRoute({ children, path }: Props) {
  const { authenticatedUser } = useAuth();

  return (
    <Route
      path={path}
      render={({ location }) => {
        return authenticatedUser ? (
          <ChildrenContainer>{children}</ChildrenContainer>
        ) : (
          <Redirect to={{ pathname: '/logga-in', state: { from: location } }} />
        );
      }}
    />
  );
}
