const getTargetEnvHost = () => {
  switch (process.env.TARGET_ENV) {
    case 'PROD':
      return 'https://tipsstugan.se';
    case 'LOCAL':
    default:
      return 'http://localhost:3001';
  }
};

export const API_HOST = typeof window === 'object' ? '' : getTargetEnvHost();
