import * as React from 'react';
import { render } from 'react-dom';
import { App } from './components/App';
import 'moment/locale/sv';
import moment from 'moment';
moment.locale('sv');

const rootEl = document.getElementById('root');

render(<App />, rootEl);
