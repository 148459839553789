import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Collapse,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BetSlipPoints, calculateBetSlipPoints, FinalStageType, GameBetPoints } from '../utils/betSlip.util';

import { createTeamsTableData } from '../utils/group.util';
import { useGetBetSlipQuery, useGetUserBySlugQuery } from '../resources/generated/tipsstugan-cms';
import { defaultOptions } from '../resources';
import { useAuth } from '../context/AuthContext';
import { getGameBetScore, getGameLabel, getGameScore, teamlogoFallback } from '../utils/game.util';
import { correctTeamInStagePointsMap, hasFinishedPreviousStage } from '../utils/excelBetSlip.util';

import { useBetslipContext } from '../context/BetslipContext';
import { useLocalStorage } from '../hooks/useLocalStorage';

const useStyles = makeStyles(() =>
  createStyles({
    teamImage: {
      margin: '0 8px',
      width: '32px',
      height: '32px',
      objectFit: 'cover',
    },
    teamImageSmall: {
      margin: '0 4px',
      width: '20px',
      height: '20px',
      objectFit: 'cover',
    },
    score: {
      width: '50px',
      textAlign: 'center',
      letterSpacing: '2px',
    },
    homeTeamName: {
      width: '36px',
      textAlign: 'right',
    },
    awayTeamName: {
      width: '36px',
      textAlign: 'left',
    },
    noBorder: {
      '& td': {
        border: 'none',
      },
    },
  }),
);

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const GameTableRow = styled(TableRow)`
  cursor: pointer;
`;

const GameTableWithImageRow = styled(TableRow)<{ backgroundImageUrl?: string | null }>`
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  background-size: 200px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-color: rgba(255, 255, 255, 0.7);
  background-blend-mode: lighten;
`;

const GameContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
`;

const TableCellSmall = styled(TableCell)`
  width: 10%;
`;

type Params = {
  slug: string;
};

// const placeholderTeamNameRegex = /W\d{2}/;
// const hasHomeTeam = ({ homeTeam }: GraphQL.Game) => !placeholderTeamNameRegex.test(homeTeam?.shortName || '');
// const hasAwayTeam = ({ awayTeam }: GraphQL.Game) => !placeholderTeamNameRegex.test(awayTeam?.shortName || '');

type GameBetTableRowProps = {
  game: GraphQL.Game;
  user: GraphQL.User;
  gameBetPoints?: GameBetPoints | null;
};

const GameBetTableRow = ({ user, gameBetPoints, game }: GameBetTableRowProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { id, status, stage } = game;
  const homeTeam = stage?.mode === 'group' ? game.homeTeam : gameBetPoints?.gameBet?.homeTeam;
  const awayTeam = stage?.mode === 'group' ? game.awayTeam : gameBetPoints?.gameBet?.awayTeam;

  return (
    <>
      <GameTableRow hover key={id} style={{ marginBottom: '12px' }} onClick={() => setOpen(!open)}>
        <TableCell>
          <GameContainer>
            <span className={classes.homeTeamName}>{homeTeam?.shortName}</span>
            <img
              className={classes.teamImage}
              src={homeTeam?.image?.url || teamlogoFallback}
              alt={homeTeam?.name + ' ikon'}
            />
            <span className={classes.score}>{getGameBetScore(game, user.betSlip)}</span>
            <img
              className={classes.teamImage}
              src={awayTeam?.image?.url || teamlogoFallback}
              alt={awayTeam?.name + ' ikon'}
            />
            <span className={classes.awayTeamName}>{awayTeam?.shortName}</span>
          </GameContainer>
        </TableCell>
        <TableCellSmall>
          {status !== 'UPCOMING' && gameBetPoints?.total !== undefined ? `${gameBetPoints?.total}p` : '-'}
        </TableCellSmall>
      </GameTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>{getGameLabel(game)}</TableCell>
                  <TableCell style={{ width: '1%' }}></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <GameContainer>
                      <span>{game.homeTeam?.shortName}</span>
                      <img
                        className={classes.teamImageSmall}
                        src={game.homeTeam?.image?.url || teamlogoFallback}
                        alt={game.homeTeam?.name + ' ikon'}
                      />
                      <span className={classes.score}>{getGameScore(game)}</span>
                      <img
                        className={classes.teamImageSmall}
                        src={game.awayTeam?.image?.url || teamlogoFallback}
                        alt={game.awayTeam?.name + ' ikon'}
                      />
                      <span className={classes.awayTeamName}>{game.awayTeam?.shortName}</span>
                    </GameContainer>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Poänguträkning:</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {/* 
                {game.stage?.mode !== 'group' ? (
                  <>
                    <TableRow>
                      <TableCell>Hemmalag i {game.stage?.name?.toLocaleLowerCase()}:</TableCell>
                      <TableCellSmall>
                        {hasHomeTeam(game) ? `${gameBetPoints?.homeTeamPoints}p` : '-'}
                      </TableCellSmall>
                    </TableRow>
                    <TableRow>
                      <TableCell>Bortalag i {game.stage?.name?.toLocaleLowerCase()}:</TableCell>
                      <TableCellSmall>
                        {hasAwayTeam(game) ? `${gameBetPoints?.awayTeamPoints}p` : '-'}
                      </TableCellSmall>
                    </TableRow>
                  </>
                ) : null}
              */}
                <>
                  <TableRow>
                    <TableCell>Hemmamål:</TableCell>
                    <TableCellSmall>
                      {game.status === 'UPCOMING' ? '-' : `${gameBetPoints?.homeScorePoints}p`}
                    </TableCellSmall>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bortamål:</TableCell>
                    <TableCellSmall>
                      {game.status === 'UPCOMING' ? '-' : `${gameBetPoints?.awayScorePoints}p`}
                    </TableCellSmall>
                  </TableRow>
                </>
                <TableRow>
                  <TableCell>1X2:</TableCell>
                  <TableCellSmall>
                    {game.status === 'UPCOMING' ? '-' : `${gameBetPoints?.resultPoints}p`}
                  </TableCellSmall>
                </TableRow>

                <TableRow>
                  <TableCell>Totalt:</TableCell>
                  <TableCellSmall>
                    {game.status === 'UPCOMING' ? '-' : `${gameBetPoints?.total || 0}p`}
                  </TableCellSmall>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const GroupBetsTable = ({ groupBets, group }: { groupBets: GraphQL.GroupBet[]; group: GraphQL.Group }) => {
  const [open, setOpen] = React.useState(false);
  const groupBet = groupBets.find((groupBet) => groupBet.group?.id === group.id);
  const teamsTableData = createTeamsTableData(group.teams, group.games);
  const groupBetPointsItems = [
    groupBet?.firstPlace,
    groupBet?.secondPlace,
    groupBet?.thirdPlace,
    groupBet?.lastPlace,
  ].map((team, i) => {
    const teamTableData = teamsTableData.find((teamTableData) => team?.id == teamTableData.team.id);
    return {
      id: teamTableData?.team?.id,
      teamName: teamTableData?.team?.name,
      points: teamTableData?.place === i + 1 ? 1 : 0,
      place: teamTableData?.place,
    };
  });
  const totalPoints = groupBetPointsItems.reduce((acc, curr) => acc + curr.points, 0);

  return (
    <>
      <TableRow hover onClick={() => setOpen(!open)}>
        <TableCell>
          <Typography variant="subtitle1" component="h6">
            Placeringar
          </Typography>
        </TableCell>
        <TableCellSmall>{totalPoints}p</TableCellSmall>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {groupBetPointsItems.map((item, i) => (
                  <TableRow key={item?.id || i}>
                    <TableCell>
                      {i + 1}. {item?.teamName} ({item?.place})
                    </TableCell>
                    <TableCellSmall>{item?.points}p</TableCellSmall>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>Totalt</TableCell>
                  <TableCellSmall>{totalPoints}p</TableCellSmall>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

type LastGamePositionsProps = {
  winningTeam: GraphQL.Team | undefined | null;
  points: number;
  game: GraphQL.Game | undefined | null;
  medal: string;
};

export const LastGamePositions = ({ winningTeam, points, game, medal }: LastGamePositionsProps) => {
  return (
    <Table>
      <TableBody>
        {winningTeam && (
          <GameTableWithImageRow
            style={{
              marginBottom: '12px',
              backgroundImage: winningTeam?.image?.url ? `url(${winningTeam?.image?.url})` : '',
            }}
          >
            <TableCell>
              <GameContainer>
                <span style={{ fontSize: '32px' }}>{medal}</span>
                <span>{`${winningTeam?.name}`}</span>{' '}
              </GameContainer>
            </TableCell>
            <TableCellSmall>
              {game?.status === 'FINISHED' || game?.status === 'LIVE' ? `${points}p` : '-'}
            </TableCellSmall>
          </GameTableWithImageRow>
        )}
      </TableBody>
    </Table>
  );
};

type TopGoalSocorerProps = {
  user: GraphQL.User | undefined | null;
  betSlipPoints: BetSlipPoints | undefined | null;
  players: GraphQL.Player[] | undefined | null;
  finalGame: GraphQL.Game | undefined | null;
  isFetchingPlayers?: boolean;
  isLive: boolean;
};

const getPlayerName = (player?: GraphQL.Player | null): string => {
  if (player?.lastName) {
    return `${player.firstName?.[0]}. ${player.lastName}`;
  }
  return player?.firstName || '';
};

type GoalScorerItem = {
  imageUrl?: string | null;
  name: string;
  scoredGoals: number;
};

const calcTopScorerPosition = (index: number, goalScorers: GoalScorerItem[]) => {
  const samePointsIndexes = goalScorers.reduce(
    (acc: number[], { scoredGoals }, i) =>
      scoredGoals === goalScorers[index].scoredGoals ? [...acc, i] : acc,
    [],
  );
  return Math.min(...samePointsIndexes) ?? index;
};

const TopGoalScorer = ({
  user,
  betSlipPoints,
  players,
  finalGame,
  isFetchingPlayers,
  isLive,
}: TopGoalSocorerProps) => {
  const [open, setOpen] = React.useState(true);
  const topGoalScorers = players
    ?.map((player) => ({
      goals: player.goals,
      imageUrl: player.image?.url,
      name: getPlayerName(player),
      scoredGoals: (player.goals || []).filter(
        ({ type, minute }) => type !== 'ownGoal' && (typeof minute !== 'number' || minute <= 120),
      ).length,
    }))
    .sort((a, b) => {
      if (a.scoredGoals > b.scoredGoals) {
        return -1;
      }
      if (a.scoredGoals > b.scoredGoals) {
        return 1;
      }
      return 0;
    })
    .slice(0, 10);

  const betSlipTopScorer = {
    ...user?.betSlip?.topGoalScorer,
    scoredGoals:
      user?.betSlip?.topGoalScorer?.goals?.filter(
        ({ type, minute }) => type !== 'ownGoal' && (typeof minute !== 'number' || minute <= 120),
      ).length || 0,
    name: getPlayerName(user?.betSlip?.topGoalScorer),
  };

  console.log({ finalGame, 'betSlipPoints?.topGoalScorerPoints': betSlipPoints?.topGoalScorerPoints });

  return (
    <Grid item xs={12}>
      <div>
        <Typography variant="h6" component="h6">
          Skyttekung
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <GameTableRow hover style={{ marginBottom: '12px' }} onClick={() => setOpen(!open)}>
                <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    style={{ marginRight: '8px' }}
                    sizes="32px"
                    src={betSlipTopScorer.image?.url || ''}
                  />
                  {`${betSlipTopScorer.name} - ${betSlipTopScorer.scoredGoals} mål`}
                </TableCell>
                <TableCellSmall>
                  {finalGame?.status === 'FINISHED' || (isLive && finalGame?.status === 'LIVE')
                    ? `${betSlipPoints?.topGoalScorerPoints}p`
                    : `(${betSlipPoints?.topGoalScorerPoints}p)`}
                </TableCellSmall>
              </GameTableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              Skytteliga
                              {isFetchingPlayers && (
                                <LoadingSpinnerWrapper style={{ padding: '0 8px' }}>
                                  <CircularProgress size={16} />
                                </LoadingSpinnerWrapper>
                              )}
                            </div>
                          </TableCell>
                          <TableCell style={{ width: '1%' }}></TableCell>
                        </TableRow>
                        {topGoalScorers ? (
                          topGoalScorers.map(({ name, scoredGoals, imageUrl }, i) => (
                            <TableRow key={name}>
                              <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {calcTopScorerPosition(i, topGoalScorers) + 1}.
                                  <Avatar style={{ margin: '0 8px' }} sizes="16px" src={imageUrl || ''} />
                                  {name}
                                </div>
                              </TableCell>
                              <TableCell>{`${scoredGoals}\u00A0mål`}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <LoadingSpinnerWrapper style={{ padding: '24px' }}>
                              <CircularProgress size={30} />
                            </LoadingSpinnerWrapper>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
};

type TeamsInStageTableRowProps = {
  betSlip: GraphQL.BetSlip | null | undefined;
  betSlipPoints: BetSlipPoints | null | undefined;
  stage: GraphQL.Stage & { games: GraphQL.Game[] };
  allGames: GraphQL.Game[];
};

const TeamsInStageTableRow = ({ betSlipPoints, betSlip, stage, allGames }: TeamsInStageTableRowProps) => {
  const stageType = stage.type as FinalStageType;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const betSlipStageTeams = betSlip?.gameBets
    ?.filter(({ game }) => game?.stage?.type === stageType)
    ?.flatMap(({ homeTeam, awayTeam }) => [homeTeam, awayTeam])
    .filter((team): team is GraphQL.Team => !!team);

  const stageTeams = stage.games
    .flatMap(({ homeTeam, awayTeam }) => [homeTeam, awayTeam])
    .filter((team): team is GraphQL.Team => !!team);

  return (
    <>
      <GameTableRow hover key={stageType} style={{ marginBottom: '12px' }} onClick={() => setOpen(!open)}>
        <TableCell>Poäng för lag i {stage.name?.toLocaleLowerCase()}</TableCell>
        <TableCellSmall>
          {stage.name &&
          (hasFinishedPreviousStage(stageType, allGames) || betSlipPoints?.[`${stageType}TeamsPoints`])
            ? `${betSlipPoints?.[`${stageType}TeamsPoints`] || 0}p`
            : '-'}
        </TableCellSmall>
      </GameTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {stageTeams.map((stageTeam) => (
                  <TableRow key={stageTeam.id}>
                    <TableCell>
                      <GameContainer>
                        <img
                          className={classes.teamImageSmall}
                          src={stageTeam?.image?.url || teamlogoFallback}
                          alt={stageTeam?.name + ' ikon'}
                        />
                        <span>{stageTeam?.name}</span>
                      </GameContainer>
                    </TableCell>
                    <TableCellSmall>
                      {betSlipStageTeams?.length &&
                      betSlipStageTeams.some((team) => team.id === stageTeam?.id)
                        ? `${correctTeamInStagePointsMap[stage.type || '']}p`
                        : '-'}
                    </TableCellSmall>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

type Stage = GraphQL.Stage & {
  games: GraphQL.Game[];
};

const dayInSec = 60 * 60 * 24;

export const UserDashboard = () => {
  const { slug } = useParams<Params>();
  const { authenticatedUser } = useAuth();
  const [storedUser, setStoredUser] = useLocalStorage<GraphQL.User | null>(`user-${slug}`, null, dayInSec);
  const [storedBetslip, setStoredBetslip] = useLocalStorage<GraphQL.BetSlip | null>(
    `betslip-${slug}`,
    null,
    dayInSec,
  );

  const { data: userBySlugData = {} } = useGetUserBySlugQuery(
    { slug },
    { ...defaultOptions, enabled: !storedUser },
  );

  useEffect(() => {
    userBySlugData?.user &&
      JSON.stringify(userBySlugData.user) !== JSON.stringify(storedUser) &&
      setStoredUser(userBySlugData.user as GraphQL.User);
  }, [userBySlugData?.user, userBySlugData, storedUser, setStoredUser]);

  const { data: betSlipData = {} } = useGetBetSlipQuery(
    { id: storedUser?.betSlip?.id },
    { ...defaultOptions, enabled: !!storedUser?.betSlip?.id && !storedBetslip },
  );

  useEffect(() => {
    betSlipData?.betSlip &&
      JSON.stringify(betSlipData.betSlip) !== JSON.stringify(storedBetslip) &&
      setStoredBetslip(betSlipData.betSlip as GraphQL.BetSlip);
  }, [betSlipData?.betSlip, betSlipData, storedBetslip, setStoredBetslip]);

  const { groups, games, players, isFetchingPlayers } = useBetslipContext();
  const user = storedUser as GraphQL.User | null | undefined;
  const betSlip = storedBetslip as GraphQL.BetSlip | null | undefined;
  const allGames = (games as GraphQL.Game[]) || [];
  const allGroups = (groups as GraphQL.Group[]) || [];
  const allPlayers = (players as GraphQL.Player[]) || [];

  const betSlipPoints =
    betSlip &&
    allGames &&
    calculateBetSlipPoints({
      includeLive: true,
      betSlip: betSlip,
      games: allGames,
      groups: allGroups,
      players: allPlayers,
    });

  const finalGame = allGames?.find(({ stage }) => stage?.type === 'final');
  const bronzeMedalGame = allGames?.find(({ stage }) => stage?.type === 'bronzeMedalGame');
  const knockOutStages = allGames
    ?.filter((game) => game.stage?.mode !== 'group')
    ?.reduce((stages: Stage[], game) => {
      if (stages.some((stage) => stage.type === game.stage?.type)) {
        return stages.map((stage) =>
          stage.type === game.stage?.type
            ? {
                ...stage,
                games: [...stage.games, game],
              }
            : stage,
        );
      }
      return game.stage ? [...stages, { ...game.stage, games: [game] }] : stages;
    }, [])
    .map((stage) => ({
      ...stage,
      games: stage.games.sort((a, b) => ((a.gameNumber || 0) > (b.gameNumber || 0) ? 1 : 0)),
    }));

  return (
    <Container style={{ height: '100%' }}>
      {user ? (
        <Card>
          <CardHeader
            avatar={<Avatar src={user.image?.url || ''} />}
            title={
              <>
                {user.username} 's tips {user?.id === authenticatedUser?.id && <strong> (Du)</strong>}
              </>
            }
            action={
              betSlipPoints ? (
                <Typography variant="h6" component="h6">
                  {betSlipPoints?.total || 0}p
                </Typography>
              ) : null
            }
          />
          <CardContent>
            <Grid container spacing={3}>
              {allGroups?.map((group) => (
                <Grid item xs={12} md={6}>
                  <div>
                    <Typography variant="h6" component="h6">
                      {group.name}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {group.games?.map((game) => (
                            <GameBetTableRow
                              key={game.id}
                              user={user}
                              gameBetPoints={betSlipPoints?.gameBetsPoints[game.id]}
                              game={game}
                            />
                          ))}
                          {user.betSlip?.groupBets?.length ? (
                            <GroupBetsTable groupBets={user.betSlip.groupBets} group={group} />
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '12px', maxWidth: '612px' }}>
              {knockOutStages?.map((stage) => (
                <Grid key={stage.id} item xs={12}>
                  <div>
                    <Typography variant="h6" component="h6">
                      {stage.name}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TeamsInStageTableRow
                            betSlip={betSlip}
                            stage={stage}
                            betSlipPoints={betSlipPoints}
                            allGames={games as GraphQL.Game[]}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {stage.games.map((game) => (
                            <GameBetTableRow
                              key={game.id}
                              user={user}
                              gameBetPoints={betSlipPoints?.gameBetsPoints[game.id]}
                              game={game}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      {stage.type === 'final' && betSlip?.champions && (
                        <LastGamePositions
                          points={betSlipPoints?.championPoints || 0}
                          game={finalGame}
                          winningTeam={betSlip.champions}
                          medal="🥇"
                        />
                      )}
                      {stage.type === 'bronzeMedalGame' && betSlip?.bronzeGameWinners && (
                        <LastGamePositions
                          points={betSlipPoints?.bronzeMedalGameTeamsPoints || 0}
                          game={bronzeMedalGame}
                          winningTeam={betSlip.bronzeGameWinners}
                          medal="🥉"
                        />
                      )}
                    </TableContainer>
                  </div>
                </Grid>
              ))}
              <TopGoalScorer
                user={user}
                finalGame={finalGame}
                players={allPlayers}
                betSlipPoints={betSlipPoints}
                isFetchingPlayers={isFetchingPlayers}
                isLive={true}
              />
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <LoadingSpinnerWrapper>
          <CircularProgress size={60} />
        </LoadingSpinnerWrapper>
      )}
    </Container>
  );
};
