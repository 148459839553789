import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Container, TextField, Paper, CircularProgress } from '@material-ui/core';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';

import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    form: {
      padding: theme.spacing(1, 2),
    },
    input: {
      margin: theme.spacing(1, 0),
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const LogoBackground = styled.div`
  display: flex;
  justify-content: center;
  width: 180px;
  height: 180px;
  padding: 40px;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;

interface LocationState {
  from: {
    pathname: string;
  };
}
export const SignIn = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { from } = location.state || { from: { pathname: '/' } };
  const { authenticatedUser, isLoading, signIn } = useAuth();

  useEffect(() => {
    !!authenticatedUser && history.replace(from);
  }, [authenticatedUser]);

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setUsername(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setPassword(event.target.value);
  };

  const onSignInClick = () => {
    if (username && password && !errorMessage) {
      signIn?.(username, password);
    }
  };

  if (isLoading) {
    <Container className={classes.root}>
      <h2>Laddar...</h2>
    </Container>;
  }

  return !authenticatedUser ? (
    <Container className={classes.root}>
      <Paper elevation={3} style={{ maxWidth: '600px' }} className={classes.form}>
        <div className={classes.logoContainer}>
          <LogoBackground>
            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/e/e3/2022_FIFA_World_Cup.svg/1200px-2022_FIFA_World_Cup.svg.png" />
          </LogoBackground>
        </div>
        <form noValidate autoComplete="off">
          <TextField
            disabled={isLoading}
            error={!!errorMessage}
            className={classes.input}
            onChange={onUsernameChange}
            value={username}
            fullWidth
            required
            id="username"
            label="Användarnamn"
            variant="outlined"
            autoComplete="username"
          />
          <TextField
            disabled={isLoading}
            error={!!errorMessage}
            helperText={errorMessage}
            className={classes.input}
            onChange={onPasswordChange}
            value={password}
            fullWidth
            required
            id="password"
            label="Lösenord"
            type="password"
            autoComplete="password"
            variant="outlined"
          />
          <Button
            disabled={isLoading}
            className={classes.input}
            variant="contained"
            size="large"
            style={{ backgroundColor: 'rgb(138 21 56)', color: 'white' }}
            startIcon={isLoading ? <CircularProgress size={20} /> : <LockOpenOutlinedIcon />}
            fullWidth
            onClick={onSignInClick}
          >
            Logga in
          </Button>
        </form>
      </Paper>
    </Container>
  ) : (
    <Redirect to={from} />
  );
};
