import moment from 'moment';

export const getTeamGameGoals = (
  goals: GraphQL.Goal[],
  teamId: string,
  { normalTimeOnly }: { normalTimeOnly: boolean } = { normalTimeOnly: false },
): number =>
  goals.filter((goal) => (!normalTimeOnly || (goal.minute || 0) <= 90) && goal.team?.id === teamId).length;

export const getGameScore = ({ goals, homeTeam, awayTeam, stage, status }: GraphQL.Game): string => {
  if (status === 'UPCOMING') {
    return ' - ';
  }

  const homeScore = getTeamGameGoals(goals || [], homeTeam?.id || '', {
    normalTimeOnly: stage?.mode !== 'group',
  });

  const awayScore = getTeamGameGoals(goals || [], awayTeam?.id || '', {
    normalTimeOnly: stage?.mode !== 'group',
  });

  return `${homeScore} - ${awayScore}`;
};

export const getGameLabel = (game: GraphQL.Game): string => {
  const { stage, status, startTime } = game;
  if (status === 'FINISHED' || status === 'LIVE') {
    const statusText = status === 'LIVE' ? ' (Live)' : ' (Slut)';
    return `Matchresultat ${stage?.mode !== 'group' && status !== 'LIVE' ? ' - 90min' : ''} ${statusText}`;
  }

  return `Matchen spelas ${moment(startTime).format('M/D HH:mm')}`;
};

export const getGameBetScore = (game: GraphQL.Game, betSlip?: GraphQL.BetSlip | null): string => {
  const gameBet = betSlip?.gameBets?.find((gameBet) => gameBet.game?.id === game.id);

  return `${gameBet?.homeScore} - ${gameBet?.awayScore}`;
};

export const teamlogoFallback =
  'https://portadownrugby.co.uk/wp-content/themes/victory/includes/images/badge-placeholder.png';
