import { useWindowSize } from '../hooks/useWindowSize';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import React from 'react';
import { createTeamsTableData } from '../utils/group.util';
import { useGetGroupsQuery } from '../resources/generated/tipsstugan-cms';
import { defaultOptions } from '../resources';

const teamlogoFallback =
  'https://portadownrugby.co.uk/wp-content/themes/victory/includes/images/badge-placeholder.png';
const TableCellTeamName = styled(TableCell)`
  display: flex;
  align-items: center;

  & img {
    margin-right: 8px;
    width: 32px;
    width: 32px;
    object-fit: cover;
  }
`;

const TableCellSmall = styled(TableCell)`
  width: 10%;
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Groups = () => {
  const { data: { groups } = {} } = useGetGroupsQuery({}, defaultOptions);
  const allGroups = (groups as GraphQL.Group[]) || [];
  const { width } = useWindowSize();
  const tableColumnHeaders = [
    { label: 'Lag', shortLabel: 'Lag' },
    { label: 'Spelade Matcher', shortLabel: 'M' },
    { label: 'Vunna matcher', shortLabel: 'V' },
    { label: 'Oavgjorda matcher', shortLabel: 'O' },
    { label: 'Förlorade matcher', shortLabel: 'F' },
    { label: 'Målskillnad', shortLabel: '+/-' },
    { label: 'Poäng', shortLabel: 'P' },
  ];

  return (
    <Container style={{ paddingTop: '96px', height: '100%' }}>
      <>
        {!allGroups && (
          <LoadingSpinnerWrapper>
            <CircularProgress size={60} />
          </LoadingSpinnerWrapper>
        )}
        <Grid container spacing={3}>
          {allGroups?.map(({ id: groupId, name, teams, games }) => {
            const teamsTableData = createTeamsTableData(teams, games);

            return (
              <Grid key={groupId} item xs={12} md={6}>
                <Typography variant="h6" component="h6">
                  {name}
                </Typography>
                <TableContainer key={groupId} component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {tableColumnHeaders.map(({ shortLabel }, i) => (
                          <TableCell key={shortLabel} align={i === 0 ? 'left' : 'center'}>
                            {shortLabel}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teamsTableData.map((teamTableData) => (
                        <TableRow key={teamTableData.team.id}>
                          <TableCellTeamName>
                            <img
                              src={teamTableData.team?.image?.url || teamlogoFallback}
                              alt={teamTableData.team.name + ' ikon'}
                            />
                            {(width || 0) > 520 ? teamTableData.team.name : teamTableData.team.shortName}
                          </TableCellTeamName>
                          <TableCellSmall align="center">{teamTableData.playedGamesCount}</TableCellSmall>
                          <TableCellSmall align="center">{teamTableData.wonGamesCount}</TableCellSmall>
                          <TableCellSmall align="center">{teamTableData.drawedGamesCount}</TableCellSmall>
                          <TableCellSmall align="center">{teamTableData.lostGamesCount}</TableCellSmall>
                          <TableCellSmall align="center">{teamTableData.goalDifferance}</TableCellSmall>
                          <TableCellSmall align="center">{teamTableData.points}</TableCellSmall>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            );
          })}
        </Grid>
      </>
    </Container>
  );
};
